import axios from "axios";

const DEALER_API_BASE_URL =
  "https://api.richgoldshine.com/quick/api/v1/vendors";

class VendorService {
  getVendors() {
    return axios.get(DEALER_API_BASE_URL);
  }

  createVendor(vendor) {
    return axios.post(DEALER_API_BASE_URL, vendor);
  }

  getVendorById(vendorId) {
    return axios.get(DEALER_API_BASE_URL + "/" + vendorId);
  }

  updateVendor(vendor, vendorId) {
    return axios.put(DEALER_API_BASE_URL + "/" + vendorId, vendor);
  }

  deleteVendor(vendorId) {
    return axios.delete(DEALER_API_BASE_URL + "/" + vendorId);
  }

  createVendorInquiry(dealerInquiry) {
    return axios.post(DEALER_API_BASE_URL + "/dealerInquiry", dealerInquiry);
  }

  getVendorsInquiry() {
    return axios.get(DEALER_API_BASE_URL + "/dealerInquiry");
  }

  getVendorByBrandId(brandId) {
    return axios.get(DEALER_API_BASE_URL + "/dealerInquiry" + brandId);
  }

  createAvailability(carAvailability) {
    return axios.post(
      DEALER_API_BASE_URL + "/dealerCarAvailability",
      carAvailability
    );
  }

  getCarAvailability() {
    return axios.get(DEALER_API_BASE_URL + "/carAvailability");
  }
}

export default new VendorService();
