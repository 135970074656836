import axios from "axios";

const DEALER_API_BASE_URL = "https://api.richgoldshine.com/quick/api/v1/riders";

class RiderService {
  getRiders() {
    return axios.get(DEALER_API_BASE_URL);
  }

  createRider(rider) {
    return axios.post(DEALER_API_BASE_URL, rider);
  }

  getRiderById(riderId) {
    return axios.get(DEALER_API_BASE_URL + "/" + riderId);
  }

  updateRider(rider, riderId) {
    return axios.put(DEALER_API_BASE_URL + "/" + riderId, rider);
  }

  deleteRider(riderId) {
    return axios.delete(DEALER_API_BASE_URL + "/" + riderId);
  }
}

export default new RiderService();
