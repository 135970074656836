import React, { Component } from "react";
import AuthService from "../services/auth.service";
import { Redirect } from "react-router-dom";

class SideBarStoreComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
        currentUser: null,
    };
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      this.setState({ redirect: "/login" });
    }
    this.setState({ currentUser: currentUser, userReady: true });
    console.log("currentUser"+JSON.stringify(currentUser?.roles));

    if (!currentUser?.roles.includes("ROLE_STORE")) {
      console.log("currentUser"+JSON.stringify(currentUser.roles));
      this.setState({ redirect: "/noaccess" });
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <div>
        {/* <!-- Sidebar --> */}
        <ul
          class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
          id="accordionSidebar"
        >
          {/* <!-- Sidebar - Brand --> */}
          <a
            class="sidebar-brand d-flex align-items-center justify-content-center"
            href="/dashboardStore"
          >
            <div class="sidebar-brand-icon rotate-n-0">
            <i><img class="img-profile rounded-circle" src="/images/quik_delivary_logo.svg" ></img></i>

            </div>
            {/* <div class="sidebar-brand-text mx-3">Quick Delivary</div> */}
          </a>

          {/* <!-- Divider --> */}
          <hr class="sidebar-divider my-0" />

          <li class="nav-item active">
            <a class="nav-link" href="/dashboardStore">
              <i class="fas fa-fw fa-tachometer-alt"></i>
              <span>Dashboard</span>
            </a>
          </li>

          {/* <!-- Divider --> */}
          <hr class="sidebar-divider" />

          {/* <!-- Heading --> */}
          <div class="sidebar-heading">Order</div>

          {/* <!-- Nav Item - Customer Pages Collapse Menu --> */}
          <li class="nav-item">
            <a
              class="nav-link collapsed"
              href="#!"
              data-toggle="collapse"
              data-target="#collapseCustomerPages"
              aria-expanded="true"
              aria-controls="collapseCustomerPages"
            >
              <i class="fa fa-shopping-cart" aria-hidden="true"></i>
              <span><strong>Order</strong></span>
            </a>
            <div
              id="collapseCustomerPages"
              class="collapse"
              aria-labelledby="headingPages"
              data-parent="#accordionSidebar"
            >
              <div class="bg-white py-2 collapse-inner rounded">
                <a class="collapse-item" href="/add-order/0">
                  Add Order
                </a>
                <a class="collapse-item" href="/orders">
                  List Orders
                </a>
              </div>
            </div>
          </li>

          {/* <!-- Nav Item - Booking --> */}
          {/* <li class="nav-item">
    <a class="nav-link" href="charts.html">
    <i class="fas fa-book"></i>
            <span>Booking</span></a>
</li> */}

          {/* <!-- Nav Item - Management --> */}
          {/* <li class="nav-item">
    <a class="nav-link" href="tables.html">
    <i class="fas fa-tasks"></i>
        <span>Management</span></a>
</li> */}
          {/* <!-- Nav Item - Report --> */}
          {/* <li class="nav-item">
    <a class="nav-link" href="tables.html">
        <i class="fas fa-fw fa-table"></i>
        <span>Report</span></a>
</li> */}

          {/* <!-- Nav Item - Setting --> */}
          {/* <li class="nav-item">
    <a class="nav-link" href="tables.html">
    <i class="fas fa-cog"></i>
        <span>Setting</span></a>
</li> */}

          {/* <!-- Divider --> */}
          <hr class="sidebar-divider d-none d-md-block" />

          {/* <!-- Sidebar Toggler (Sidebar) --> */}
          {/* <div class="text-center d-none d-md-inline">
    <button class="rounded-circle border-0" id="sidebarToggle"></button>
</div> */}
        </ul>
        {/* <!-- End of Sidebar --> */}
      </div>
    );
  }
}

export default SideBarStoreComponent;
