import React, { Component } from "react";
import RiderService from "../services/RiderService";

import SideBarComponent from "./SideBarComponent";
import BackEndFooterComponent from "./BackEndFooterComponent";
import TopBarComponent from "./TopBarComponent";
import { faEye, faEdit, faTrash,faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

class ListRiderComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableData: {},
      riders: [],
      columns: [
        {
          name: "Rider Id",
          selector: (row) => row.riderId,
          cellExport: (row) => row.riderId,
          sortable: true,
        },
        {
          name: "Rider Name",
          selector: (row) => row.fullname,
          cellExport: (row) => row.fullname,

          sortable: true,
        },
        {
          name: "Rider Contact",
          selector: (row) => row.mobile,
          cellExport: (row) => row.mobile,
          sortable: true,
          right: true,
        },
        {
          name: "Rider Aadhar",
          selector: (row) => row.aadhar,
          cellExport: (row) => row.aadhar,
          sortable: true,
          right: true,
        },
        {
          name: "Rider Address",
          selector: (row) => row.address,
          cellExport: (row) => row.address,
          sortable: true,
        },
        {
          cell: (row) => (
            <a href={`/view-rider/${row["riderId"]}`}>
              <FontAwesomeIcon icon={faEye} />
            </a>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        {
          cell: (row) => (
            <a href={`/add-rider/${row["riderId"]}`}>
              <FontAwesomeIcon icon={faEdit} />
            </a>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        {
          cell: (row) => (
            <a href={`/rider-orders/${row["riderId"]}`}>
              <FontAwesomeIcon icon={faCartShopping} />
            </a>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        {
          cell: (row) => (
            <a href="#!" onClick={() => this.clickHandler(row)}>
              <FontAwesomeIcon icon={faTrash} />
            </a>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ],
    };
    this.addRider = this.addRider.bind(this);
    this.editRider = this.editRider.bind(this);
    this.deleteRider = this.deleteRider.bind(this);
  }

  deleteRider(id) {
    RiderService.deleteRider(id).then((res) => {
      this.setState({
        rider: this.state.riders.filter((rider) => rider.riderId !== id),
      });
    });
    this.componentDidMount();
  }
  clickHandler = (row) => {
    this.deleteRider(row.riderId);
  };
  viewRider(id) {
    this.props.history.push(`/view-rider/${id}`);
  }
  editRider(id) {
    this.props.history.push(`/add-rider/${id}`);
  }

  componentDidMount() {
    RiderService.getRiders().then((res) => {
      console.log(res.data);
      this.setState({ riders: res.data });
      this.setState({
        tableData: { columns: this.state.columns, data: res.data },
      });
    });
  }

  addRider() {
    this.props.history.push("/add-rider/0");
  }

  render() {
    return (
      // <!-- Page Wrapper -->
      <div id="wrapper">
        <SideBarComponent />

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" class="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            <TopBarComponent />

            {/* <!-- Begin Page Content --> */}
            <div class="container-fluid">
              {/* <!-- Page Heading --> */}
              {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Rider</h1>
                
                </div> */}

              {/* <!-- Content Row --> */}
              {/* <!-- Content Row --> */}
              <div class="row">
                <div class="col-xl-12 col-lg-12">
                  {/* <!-- Area Chart --> */}
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <h6 class="m-0 font-weight-bold text-primary d-flex justify-content-between align-items-center">
                        List Rider
                        <button className="btn btn-sm btn-primary" onClick={this.addRider}> Add Rider</button>
                      </h6>
                    </div>
                    <div class="card-body">
       
                      <div className="row">
                   
                         <DataTableExtensions {...this.state.tableData}>
                          <DataTable
                            columns={this.state.columns}
                            data={this.state.riders}
                            noHeader
                            defaultSortField="id"
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover
                            selectableRows
                            print={false}
                            exportHeaders={false}
                            onSelectedRowsChange={this.handleChange}
                            selectedRows={this.handleChange}
                          />
                        </DataTableExtensions>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Content Row --> */}

              {/* <!-- Content Row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
          <BackEndFooterComponent />
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      // <!-- End of Page Wrapper -->
    );
  }
}

export default ListRiderComponent;
