import axios from "axios";

const API_URL = "https://api.richgoldshine.com/quick/api/auth/";

class AuthService {
  login(email, password) {
    // alert(username + password);
    return axios
      .post(API_URL + "signin", {
        email,
        password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          // alert(JSON.stringify(response.data));

          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }
  changePassword(id, user) {
    // alert(JSON.stringify(user));
    return axios.put(API_URL + "update/" + id, user);
  }

  register(username, email, password, role) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password,
      role,
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default new AuthService();
