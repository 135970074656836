import axios from "axios";

const DASHBOARD_API_BASE_URL =
  "https://api.richgoldshine.com/quick/api/v1/dashboard";

class DashboardService {
  getDashboardData() {
    return axios.get(DASHBOARD_API_BASE_URL);
  }

  getStoreDashboardData(storeId) {
    return axios.get(DASHBOARD_API_BASE_URL + "/" + storeId);
  }

  createBrand(brand) {
    return axios.post(DASHBOARD_API_BASE_URL, brand);
  }

  getBrandById(brandId) {
    return axios.get(DASHBOARD_API_BASE_URL + "/" + brandId);
  }

  updateBrand(brand, brandId) {
    return axios.put(DASHBOARD_API_BASE_URL + "/" + brandId, brand);
  }

  deleteBrand(brandId) {
    return axios.delete(DASHBOARD_API_BASE_URL + "/" + brandId);
  }
}

export default new DashboardService();
