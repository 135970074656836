import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import AuthService from "../services/auth.service";
import StoreService from "../services/StoreService";
import VendorService from "../services/VendorService";
import SideBarComponent from "./SideBarComponent";
import BackEndFooterComponent from "./BackEndFooterComponent";
import TopBarComponent from "./TopBarComponent";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

class CreateStoreComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // step 2
      id: this.props.match.params.id,
      storeName: "", storeNameVal: "",

      storeEmail: "", storeEmailVal: "",

      address: "", addressVal: "",

      storeAddress: "", storeAddressVal: "",

      storeLatitude: 0.0, storeLatitudeVal: '',

      storeLongitude: 0.0, storeLongitudeVal: '',

      storeMobileNumber: "", storeMobileNumberVal: '',

      postalCode: "", postalCodeVal: "",

      vendors: [], vendorsVal: [],

      vendorId: 0, vendorIdVal: 0,

      currentUser: null,
      searchOptions: {
        componentRestrictions: { country: ['in'] }
      }
    };
    this.changeStoreNameHandler = this.changeStoreNameHandler.bind(this);
    this.saveOrUpdateStore = this.saveOrUpdateStore.bind(this);
  }

  // step 3
  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      this.setState({ redirect: "/login" });
    }
    this.setState({ currentUser: currentUser, userReady: true });
    console.log("currentUser" + JSON.stringify(currentUser?.roles));

    if (!currentUser?.roles.includes("ROLE_ADMIN")) {
      console.log("currentUser" + JSON.stringify(currentUser.roles));
      this.setState({ redirect: "/noaccess" });
    }

    VendorService.getVendors().then((res) => {
      this.setState({ vendors: res.data });
    });

    // step 4
    if (this.state.id === "0") {
      return;
    } else {
      StoreService.getStoreById(this.state.id).then((res) => {
        let store = res.data;
        this.setState({
          storeName: store.storeName,
          storeAddress: store.storeAddress,
          postalCode: store.postalCode,
          storeMobileNumber: store.storeMobileNumber,
          storeLatitude: store.storeLatitude,
          storeLongitude: store.storeLongitude,
          vendorId: store?.vendor?.vendorId
        });
      });
    }
  }
  saveOrUpdateStore = (e) => {
    e.preventDefault();


    if (this.state.storeName === "") {
      this.setState({ storeNameVal: "Invalid!, Please enter Store Name" });
      return false;
    }
    else {
      this.setState({ storeNameVal: "" });
    }
    if (this.state.vendorId === 0) {
      this.setState({ vendorsVal: "Invalid!, Please enter Vendor" });
      return false;
    }
    else {
      this.setState({ vendorsVal: "" });
    }


    if (this.state.storeMobileNumber === "") {
      this.setState({
        storeMobileNumberVal: "Invalid!, Please enter Contact Number ",
      });
      return false;
    }
    else {
      this.setState({ storeMobileNumberVal: "" });
    }
    if (this.state.storeAddress === "") {
      this.setState({
        storeAddressVal: "Invalid!, Please enter Store Address",
      });
      return false;
    }
    else {
      this.setState({ storeAddressVal: "" });
    }

    if (this.state.postalCode === "") {
      this.setState({
        postalCodeVal: "Invalid!, Please enter  Postal Code",
      });
      return false;

    }
    else {
      this.setState({ postalCodeVal: "" });
    }

    let store = {
      storeName: this.state.storeName,
      storeAddress: this.state.storeAddress,
      storeLatitude: this.state.storeLatitude,
      storeLongitude: this.state.storeLongitude,
      postalCode: this.state.postalCode,
      storeMobileNumber: this.state.storeMobileNumber,
      vendor: { vendorId: this.state.vendorId },
    };
    //console.log("store => " + JSON.stringify(store));

    // step 5
    if (this.state.id === "0") {
      StoreService.createStore(store).then((res) => {
        this.props.history.push("/stores");
      });
    } else {
      StoreService.updateStore(store, this.state.id).then((res) => {
        this.props.history.push("/stores");
      });
    }
  };

  changeStoreNameHandler = (event) => {
    this.setState({ storeName: event.target.value });
  };
  changeVendorIdHandler = (event) => {
    this.setState({ vendorId: event.target.value });
  };

  changeCityIdHandler = (event) => {
    this.setState({ cityId: event.target.value });
  };

  changeStoreAddressHandler = (event) => {
    this.setState({ storeAddress: event.target.value });
  };

  changePostalCodeHandler = (event) => {
    this.setState({ postalCode: event.target.value });
  };
  changestoreNumberHandler = (event) => {
    this.setState({ storeMobileNumber: event.target.value });
  };

  changeStoreLatitudeHandler = (event) => {
    this.setState({ storeLatitude: event.target.value });
  };

  changeStoreLongitudeHandler = (event) => {
    this.setState({ storeLongitude: event.target.value });
  };

  cancel() {
    this.props.history.push("/Stores");
  }
  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    this.setState({ storeAddress: address });

    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.setState({ storeLatitude: latLng.lat });
        this.setState({ storeLongitude: latLng.lng });
      })
      .catch((error) => console.error("Error", error));
  };
  getTitle() {
    if (this.state.id === "0") {
      return <h6 className="m-0 font-weight-bold text-primary">Add Store</h6>;
    } else {
      return (
        <h6 className="m-0 font-weight-bold text-primary">Update Store</h6>
      );
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    //     const { currentUser } = this.state;

    return (
      // <!-- Page Wrapper -->
      <div id="wrapper">
        <SideBarComponent />

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" class="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            <TopBarComponent />

            {/* <!-- Begin Page Content --> */}
            <div class="container-fluid">
              {/* <!-- Page Heading --> */}
              {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Store</h1>
                
                </div> */}

              {/* <!-- Content Row --> */}
              {/* <!-- Content Row --> */}
              <div class="row">
                <div class="col-xl-12 col-lg-12">
                  {/* <!-- Area Chart --> */}
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <a
                        class="btn btn-sm btn-primary float-right   mr-3"
                        href="/Stores"
                        role="button"
                      >
                        List Store
                      </a>

                      {this.getTitle()}
                    </div>
                    <div class="card-body">
                      <div className="row">
                        <div className="card col-md-6 offset-md-3 offset-md-3">
                          <div className="card-body">
                            <form>
                              <div className="form-group">
                                <label> Store Name: </label>
                                <input
                                  placeholder="Store Name"
                                  name="storeName"
                                  className="form-control"
                                  value={this.state.storeName}
                                  onChange={this.changeStoreNameHandler}
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.storeNameVal}
                                </p>
                              </div>

                              <div className="form-group">
                                <label> Vendor: </label>

                                <select
                                  class="form-select col-12"
                                  name="vendorId"
                                  aria-label="Default select example"
                                  onChange={this.changeVendorIdHandler}
                                >
                                  <option value="">Select Vendor</option>

                                  {this.state.vendors.map((vendor) => (
                                    <option
                                      value={vendor.vendorId}
                                      selected={
                                        this.state.vendorId === vendor.vendorId
                                      }
                                    >
                                      {vendor.vendorName}
                                    </option>
                                  ))}
                                </select>
                                <p className="text-danger">
                                  {" "}
                                  {this.state.vendorsVal}
                                </p>
                              </div>

                              <div className="form-group">
                                <label> Store Contact: </label>
                                <input
                                  placeholder="Store Contact"
                                  name="storeNumber"
                                  className="form-control"
                                  maxLength="11"
                                  value={this.state.storeMobileNumber}
                                  onChange={this.changestoreNumberHandler}
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.storeMobileNumberVal}
                                </p>
                              </div>
                              <PlacesAutocomplete
                                value={this.state.address}
                                onChange={this.handleChange}
                                onSelect={this.handleSelect}
                                searchOptions={this.state.searchOptions}
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps,
                                  loading,
                                }) => (
                                  <div>
                                    <input
                                      {...getInputProps({
                                        placeholder: "Search Places ...",
                                        className: "location-search-input",
                                      })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                      {loading && <div>Loading...</div>}
                                      {suggestions.map((suggestion) => {
                                        const className = suggestion.active
                                          ? "suggestion-item--active"
                                          : "suggestion-item";
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                          ? {
                                            backgroundColor: "#fafafa",
                                            cursor: "pointer",
                                          }
                                          : {
                                            backgroundColor: "#ffffff",
                                            cursor: "pointer",
                                          };
                                        return (
                                          <div
                                            {...getSuggestionItemProps(
                                              suggestion,
                                              {
                                                className,
                                                style,
                                              }
                                            )}
                                          >
                                            <span>
                                              {suggestion.description}
                                            </span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                )}
                              </PlacesAutocomplete>
                              <div className="form-group">
                                <label> Store Address: </label>
                                <input
                                  placeholder="Store Address"
                                  name="storeAddress"
                                  className="form-control"
                                  value={this.state.storeAddress}
                                  onChange={this.changeStoreAddressHandler}
                                  readOnly
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.storeAddressVal}
                                </p>
                              </div>
                              <div className="form-group">
                                <label> Store Postal Code: </label>
                                <input
                                  placeholder="Store Postal Code"
                                  name="postalCode"
                                  className="form-control"
                                  value={this.state.postalCode}
                                  onChange={this.changePostalCodeHandler}
                                  maxLength="8"
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.postalCodeVal}
                                </p>
                              </div>
                              <div className="form-group">
                                <label> Store Location Lattitude: </label>
                                <input
                                  placeholder="Store Location Lattitude: "
                                  name="storeLatitude"
                                  className="form-control"
                                  value={this.state.storeLatitude}
                                  onChange={this.changeStoreLatitudeHandler}
                                  readOnly
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.storeLatitudeVal}
                                </p>
                              </div>
                              <div className="form-group">
                                <label> Store Location Longitude: </label>
                                <input
                                  placeholder="Store Location Longitude"
                                  name="storeLongitude"
                                  className="form-control"
                                  value={this.state.storeLongitude}
                                  onChange={this.changeStoreLongitudeHandler}
                                  readOnly
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.storeLongitudeVal}
                                </p>
                              </div>
                              <button
                                className="btn btn-success"
                                onClick={this.saveOrUpdateStore}
                              >
                                Save
                              </button>
                              <button
                                className="btn btn-danger"
                                onClick={this.cancel.bind(this)}
                                style={{ marginLeft: "10px" }}
                              >
                                Cancel
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Content Row --> */}

              {/* <!-- Content Row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
          <BackEndFooterComponent />
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      // <!-- End of Page Wrapper -->
    );
  }
}

export default CreateStoreComponent;
