import React, { Component } from "react";
import OrderService from "../services/OrderService";
import AuthService from "../services/auth.service";
import SideBarComponent from "./SideBarComponent";
import SideBarStoreComponent from "./SideBarStoreComponent";

import BackEndFooterComponent from "./BackEndFooterComponent";
import TopBarComponent from "./TopBarComponent";
import { Redirect } from "react-router-dom";

import { faEye, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StoreService from "../services/StoreService";
import DataTable from "react-data-table-component";
import { confirm } from "react-confirm-box";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import addDays from 'date-fns/addDays'
import DatePicker from 'react-datepicker';




class ListVendorOrderComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      value: new Date(),
      onChange: '',
      orderStatus: '',
      currentUser: null,
      startDate: addDays(new Date(), -2),
      endDate: new Date(),
      tableData: {},
      orders: [],
      selctedOrderRows: [],
      orderStatuses: [{ label: "OPEN" }, { label: "ACCEPTED" }, { label: "DELIVERED" }, { label: "CANCELLED" }],

      columns: [
        {
          name: "Id",
          selector: (row) => row.orderId,
          cellExport: (row) => row.orderId,
          sortable: true,
        },
        {
          name: "Order Number",
          selector: (row) => row.orderNumber,
          cellExport: (row) => row.orderNumber,

          sortable: true,
        },
        {
          name: "Vendor",
          selector: (row) => row.store?.vendor?.vendorName,
          cellExport: (row) => row.store?.vendor?.vendorName,

          sortable: true,
        },
        {
          name: "Store",
          selector: (row) => row.store?.storeName,
          cellExport: (row) => row.store?.storeName,

          sortable: true,
        },
        {
          name: "Order Status",
          selector: (row) => row.orderStatus,
          cellExport: (row) => row.orderStatus,

          sortable: true,
        },
        {
          name: "Update Date",
          selector: (row) => row.orderUpdateDate,
          cellExport: (row) => row.orderUpdateDate,

          sortable: true,
          width: "auto",
        },
        {
          name: "Order Distance",
          selector: (row) => row.orderDistance?.toFixed(2),
          cellExport: (row) => row.orderDistance,

          sortable: true,
        },
        {
          name: "Cost",
          selector: (row) => row.vendorCost,
          cellExport: (row) => row.vendorCost,

          sortable: true,
        },
        {
          name: "Payment Status",
          selector: (row) => row.vendorPaymentStatus,
          cellExport: (row) => row.vendorPaymentStatus,
          sortable: true,
        },
        {
          name: "Payment Date",
          selector: (row) => row.vendorPaymentDate,
          cellExport: (row) => row.vendorPaymentDate,
          sortable: true,
          width: "auto",
        },

        {
          cell: (row) => (
            <a href={`/view-order/${row["orderId"]}`}>
              <FontAwesomeIcon icon={faEye} />
            </a>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ],
    };

    this.addOrder = this.addOrder.bind(this);
    this.editOrder = this.editOrder.bind(this);
    this.deleteOrder = this.deleteOrder.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);

  }

  deleteOrder(id) {
    OrderService.deleteOrder(id).then((res) => {
      this.setState({
        order: this.state.orders.filter((order) => order.id !== id),
      });
      this.componentDidMount();
    });
  }

  clickHandler = (row) => {
    this.deleteOrder(row.orderId);
  };
  updateOrder(orderId) {
    let order = {
      vendorPaymentStatus: "INVOICED",
      orderId: orderId,
    };

    // step 5
    if (orderId === "0") {
      return;
    } else {
      OrderService.updateOrder(order, orderId).then((res) => {
        this.componentDidMount();
      });
    }
  }
  handleStartDateChange(date) {
    // alert(date);

    this.setState({
      startDate: date
    })
  }

  handleEndDateChange(date) {
    // alert(date);

    this.setState({
      endDate: date
    })
  }

  orderStatusHandler = (event) => {
    this.setState({ orderStatus: event.target.value });
  };


  onFormSubmit(e) {
    e.preventDefault();
    console.log(this.state.startDate);
    console.log(this.state.endDate);
    console.log(this.state.orderStatus);

    this.componentDidMount();

  }

  invoiceVendor = (row) => {
    this.updateOrder(row.orderId);
  };
  invoiceVendorMultipleOrdersRider = () => {
    this.state.selctedOrderRows.map((row) => this.invoiceVendor(row));
  };
  calculateInvoiceAmount = async () => {
    let amountPayout = 0,
      index = 0,
      invoiceableOrders = [];
    this.state.selctedOrderRows.map((row) => {
      if (
        row.orderStatus === "DELIVERED" &&
        row.vendorPaymentStatus === "UNPAID"
      ) {
        amountPayout = amountPayout + row.vendorCost;
        invoiceableOrders[index++] = row.orderId;
      }
    });

    let result = await confirm(
      "Total Invoiceable Orders: [" +
      invoiceableOrders.length +
      "] Order IDs: [" +
      invoiceableOrders +
      "] Invoiceable Amount: [" +
      amountPayout.toFixed(2) +
      "] Are you sure, You want to proceed with Invoice?"
    );
    if (result) {
      invoiceableOrders.map((orderId) => {
        this.updateOrder(orderId);
      });
    } else {
      alert("Invoice Cancelled!");
      return;
    }
  };
  handleChange = (state) => {
    this.state.selctedOrderRows = state.selectedRows;
  };
  viewOrder(id) {
    this.props.history.push(`/view-order/${id}`);
  }
  editOrder(id) {
    this.props.history.push(`/add-order/${id}`);
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      this.setState({ redirect: "/login" });
    }
    this.setState({ currentUser: currentUser, userReady: true });
    console.log("currentUser.roles :: " + currentUser?.roles);

    if (this.state.id === "0") {
      return;
    } else {

      let orderFilter = { startDate: this.state.startDate, endDate: this.state.endDate, orderStatus: this.state.orderStatus }

      OrderService.vendorOrders(this.state.id, orderFilter).then((res) => {
        this.setState({ orders: res.data });
        this.setState({
          tableData: { columns: this.state.columns, data: res.data },
        });
      });
    }
  }

  addOrder() {
    this.props.history.push("/add-order/0");
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      // <!-- Page Wrapper -->
      <div id="wrapper">
        {this.state.currentUser?.roles.includes("ROLE_ADMIN") && (
          <SideBarComponent />
        )}
        {this.state.currentUser?.roles.includes("ROLE_STORE") && (
          <SideBarStoreComponent />
        )}
        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" class="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            <TopBarComponent />

            {/* <!-- Begin Page Content --> */}
            <div class="container-fluid">
              {/* <!-- Page Heading --> */}
              {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Order</h1>
                
                </div> */}

              {/* <!-- Content Row --> */}
              {/* <!-- Content Row --> */}
              <div class="row">
                <div class="col-xl-12 col-lg-12">
                  {/* <!-- Area Chart --> */}
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <h6 class="m-0 font-weight-bold text-primary d-flex justify-content-between align-items-center">
                        List Order
                        {this.state.currentUser?.roles?.includes(
                          "ROLE_ADMIN"
                        ) && (
                            <button
                              className="btn btn-sm btn-primary"
                              onClick={this.calculateInvoiceAmount}
                            >
                              Mark Invoiced
                            </button>
                          )}
                        {this.state.currentUser?.roles?.includes(
                          "ROLE_STORE"
                        ) && (
                            <button
                              className="btn btn-sm btn-primary"
                              onClick={this.addOrder}
                            >
                              Add Order
                            </button>
                          )}
                      </h6>
                    </div>
                    <div class="card-body">
                    <form onSubmit={this.onFormSubmit}>
                        <div className="row">

                          <div className="col-md-3">Start Date:
                            <DatePicker
                              selected={this.state.startDate}
                              onChange={this.handleStartDateChange}
                              name="startDate"
                              dateFormat="dd-MM-yyyy"
                            />
                          </div>

                          <div className="col-md-3">
                            End Date: <DatePicker
                              selected={this.state.endDate}
                              onChange={this.handleEndDateChange}
                              name="endDate"
                              dateFormat="dd-MM-yyyy"
                            />

                          </div>

                          <div className="col-md-3">
                            Order Status:
                            <select
                              name="orderStatus"
                              value={this.state.orderStatus}
                              onChange={this.orderStatusHandler}
                            >
                              <option value="">
                                Select Order Status
                              </option>
                              {this.state.orderStatuses.map(
                                (orderStatus) => (
                                  <option value={orderStatus.label}>
                                    {orderStatus.label}
                                  </option>
                                )
                              )}
                            </select>
                          </div>

                          <div className="col-md-3">

                            <button className="btn btn-primary">Search</button>
                          </div>

                        </div>
                      </form>


                      <div className="row">


                        <DataTableExtensions {...this.state.tableData}>
                          <DataTable
                            columns={this.state.columns}
                            data={this.state.orders}
                            noHeader
                            defaultSortField="id"
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover
                            selectableRows
                            print={false}
                            exportHeaders={false}

                            onSelectedRowsChange={this.handleChange}
                            selectedRows={this.handleChange}
                          />
                        </DataTableExtensions>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Content Row --> */}

              {/* <!-- Content Row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
          <BackEndFooterComponent />
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      // <!-- End of Page Wrapper -->
    );
  }
}

export default ListVendorOrderComponent;
