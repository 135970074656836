import axios from "axios";

const DASHBOARD_API_BASE_URL =
  "https://api.richgoldshine.com/quick/api/v1/adminSettings";

class AdminSettingsService {
  getAdminSettingsById(adminSettingsId) {
    return axios.get(DASHBOARD_API_BASE_URL + "/" + adminSettingsId);
  }

  updateAdminSettings(adminSettings, adminSettingsId) {
    return axios.put(
      DASHBOARD_API_BASE_URL + "/" + adminSettingsId,
      adminSettings
    );
  }
}

export default new AdminSettingsService();
