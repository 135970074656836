import axios from "axios";

const ORDER_API_BASE_URL = "https://api.richgoldshine.com/quick/api/v1/orders";

class OrderService {
  // getOrders(){
  //     return axios.get(ORDER_API_BASE_URL);
  // }

  // getOrdersByDate(dateFilter){
  //     return axios.post(ORDER_API_BASE_URL+'/ordersByDate', dateFilter);
  // }

  createOrder(order) {
    return axios.post(ORDER_API_BASE_URL, order);
  }

  getOrderDistance(order) {
    return axios.post(ORDER_API_BASE_URL + "/orderDistance", order);
  }
  vendorOrders(vendorId, orderFilter) {
    return axios.post(
      ORDER_API_BASE_URL + "/vendorOrders/" + vendorId,
      orderFilter
    );
  }
  riderOrders(riderId, orderFilter) {
    return axios.post(
      ORDER_API_BASE_URL + "/riderOrders/" + riderId,
      orderFilter
    );
  }

  getOrdersByStatus(orderFilter) {
    return axios.post(ORDER_API_BASE_URL + "/ordersByStatus/", orderFilter);
  }
  getStoreOrdersByStatus(storeId, orderFilter) {
    return axios.post(
      ORDER_API_BASE_URL + "/storeOrdersByStatus/" + storeId,
      orderFilter
    );
  }
  getOrderById(orderId) {
    return axios.get(ORDER_API_BASE_URL + "/" + orderId);
  }

  updateOrder(order, orderId) {
    return axios.put(ORDER_API_BASE_URL + "/" + orderId, order);
  }

  deleteOrder(orderId) {
    return axios.delete(ORDER_API_BASE_URL + "/" + orderId);
  }
}

export default new OrderService();
