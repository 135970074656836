import React, { Component } from 'react'
import FooterComponent from './FooterComponent';


class AboutUsComponent extends Component {




render() {

return (
<div>
  <header>
    <header>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <a className="navbar-brand" href="/"><img src='/images/logo.jpeg' width="70px" ></img></a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav">




            <li className="nav-item">
              <a className="nav-link" href="/about-us">About Us</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/contact-us">Contact Us</a>
            </li>
          </ul>


          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a className="nav-link" href="/login">Login</a>

              {/*
              <Link to="/login" className="nav-link"> Login </Link> */}

            </li>

          </ul>
        </div>
      </nav>

    </header>

  </header>

  <div id="demo" className="carousel slide" data-ride="carousel">

    {/*
    <!-- Indicators --> */}
    {/* <ul className="carousel-indicators">
      <li data-target="#demo" data-slide-to="0" className="active"></li>
      <li data-target="#demo" data-slide-to="1"></li>
      <li data-target="#demo" data-slide-to="2"></li>
    </ul> */}

    {/*
    <!-- The slideshow --> */}
    <div className="carousel-inner">
      {/* <div className="carousel-item">
        <img src="/images/car-3.jpg" alt="New York" className="img-fluid" width="100%" height="500" />
      </div> */}
      <div className="carousel-item active">
        <img src="/images/slider4.jpg" alt="Los Angeles" className="img-fluid" width="100%"
          height="500" />
      </div>
      {/* <div className="carousel-item">
        <img src="/images/ezgif.com-gif-maker-2.jpg" alt="Chicago" className="img-fluid" width="100%" height="500" />
      </div> */}

    </div>

    {/*
    <!-- Left and right controls --> */}
    {/* <a className="carousel-control-prev" href="#demo" data-slide="prev">
      <span className="carousel-control-prev-icon"></span>
    </a>
    <a className="carousel-control-next" href="#demo" data-slide="next">
      <span className="carousel-control-next-icon"></span>
    </a> */}
  </div>
  <br></br>


  <div className="container">
    <div className="row">

      <div className="col-md-6">


        <h1 className='mb-4'><b>ABOUT US</b></h1>

        <p>Cars are what BSECars builds. Experiences are what it creates.
        </p>
        <p> BSECARS is a main two-sided computerized car commercial centre that interfaces vehicle customers with
          merchants. Propelled in 2017 and headquartered in Pune, the organization engages customers with the
          information, assets and computerized apparatuses expected to settle on educated purchasing choices and
          flawlessly associate with car retailers. </p>
        <p>In a quickly evolving business sector, Bsecars.com empowers car vendors and producers with creative
          specialized arrangements and information driven insight to more readily reach and impact prepared to-purchase
          customers, increment stock turn and addition piece of the overall industry.</p>
        <p>In 2018, BSECARS obtained, an imaginative innovation organization building arrangements that
          future-confirmation businesses with progressively proficient activities, a quicker and simpler vehicle
          purchasing process, and associated advanced encounters that sell and administration more vehicles.</p>




      </div>
      <div className="col-md-6">

        <img src="./images/img1.jpg" className='img-fluid'></img>




      </div>

    </div>
  </div>
  <FooterComponent />
</div>
)
}
}

export default AboutUsComponent