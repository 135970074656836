import React, { Component } from "react";
import OrderService from "../services/OrderService";
import AuthService from "../services/auth.service";
import SideBarComponent from "./SideBarComponent";
import SideBarStoreComponent from "./SideBarStoreComponent";

import BackEndFooterComponent from "./BackEndFooterComponent";
import TopBarComponent from "./TopBarComponent";
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import addDays from "date-fns/addDays";
import Tippy from "@tippyjs/react";
import "react-datepicker/dist/react-datepicker.css";

import {
  faEye,
  faEdit,
  faTrash,
  faEllipsisVertical,
  faCancel,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import { confirm } from "react-confirm-box";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

class ListOrderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderStatus: this.props.match.params.status,
      currentUser: null,
      startDate: addDays(new Date(), -2),
      endDate: new Date(),
      tableData: {},
      orders: [],
      selctedOrderRows: [],
      orderStatuses: [
        { label: "OPEN" },
        { label: "ACCEPTED" },
        { label: "DELIVERED" },
        { label: "CANCELLED" },
      ],

      columns: [
        {
          name: "Id",
          selector: (row) => row.orderId,
          cellExport: (row) => row.orderId,
          sortable: true,
          width: "auto",

        },
        {
          name: "Order Number",
          selector: (row) => row.orderNumber,
          cellExport: (row) => row.orderNumber,
          sortable: true,
        },
        {
          name: "Store",
          selector: (row) => (
            <Tippy content={row.store?.storeName}>
              <div>{row.store?.storeName}</div>
            </Tippy>
          ),
          cellExport: (row) => row.store?.storeName,

          sortable: true,
        },
        {
          name: "Cust Name",
          selector: (row) =><Tippy content={row.customerName}>
          <div>{row.customerName}</div>
        </Tippy>,
          cellExport: (row) => row.customerName,
          sortable: true,
        },
        {
          name: "Cust Contact",
          selector: (row) => row.mobileNumber,
          cellExport: (row) => row.mobileNumber,

          sortable: true,
        },
        {
          name: "Rider Name",
          selector: (row) => row.rider?.fullname,
          cellExport: (row) => row.rider?.fullname,

          sortable: true,
        },

        {
          name: "Order Date",
          selector: (row) => row.orderDate,
          cellExport: (row) => row.orderDate,
          sortable: true,
          width: "auto",
        },

        {
          name: "Order Status Date",
          selector: (row) => row.orderUpdateDate,
          cellExport: (row) => row.orderUpdateDate,
          sortable: true,
          width: "auto",
        },
        {
          name: "Order Status",
          selector: (row) => row.orderStatus,
          cellExport: (row) => row.orderStatus,

          sortable: true,
        },
        {
          cell: (row) => (
            <a href={`/view-order/${row["orderId"]}`}>
              <FontAwesomeIcon icon={faEye} />
            </a>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        {
          cell: (row) => (
            <a href={`/add-order/${row["orderId"]}`}>
              <FontAwesomeIcon icon={faEdit} />
            </a>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        {
          cell: (row) =>
            row.orderStatus === "DELIVERED" || row.orderStatus === "CANCELLED"  ? (
              ""
            ) : (
              <a href="#!" onClick={() => this.cancelHandler(row)}>
                <FontAwesomeIcon icon={faCancel} />
              </a>
            ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        {
          cell: (row) =>
            row.orderStatus === "OPEN" || row.orderStatus === "CANCELLED" ? (
              <a href="#!" onClick={() => this.clickHandler(row)}>
                <FontAwesomeIcon icon={faTrash} />
              </a>
            ) : (
              ""
            ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          
        },
      ],
    };

    this.addOrder = this.addOrder.bind(this);
    this.editOrder = this.editOrder.bind(this);
    this.deleteOrder = this.deleteOrder.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  deleteOrder(id) {
    OrderService.deleteOrder(id).then((res) => {
      this.setState({
        order: this.state.orders.filter((order) => order.id !== id),
      });
      this.componentDidMount();
    });
  }

  clickHandler = (row) => {
    this.deleteOrder(row.orderId);
  };
  cancelHandler = (row) => {
    this.cancelOrder(row.orderId);
  };
  cancelOrder(orderId) {
    let order = {
      orderStatus: "CANCELLED",
      orderId: orderId,
    };

    // step 5
    if (orderId === "0") {
      return;
    } else {
      OrderService.updateOrder(order, orderId).then((res) => {
        this.componentDidMount();
      });
    }
  }

  handleStartDateChange(date) {
    // alert(date);

    this.setState({
      startDate: date,
    });
  }

  handleEndDateChange(date) {
    // alert(date);

    this.setState({
      endDate: date,
    });
  }
  orderStatusHandler = (event) => {
    this.setState({ orderStatus: event.target.value });
  };

  onFormSubmit(e) {
    e.preventDefault();
    console.log(this.state.startDate);
    console.log(this.state.endDate);
    console.log(this.state.orderStatus);
    this.componentDidMount();
  }
  updateOrder(orderId) {
    let order = {
      vendorPaymentStatus: "INVOICED",
      orderId: orderId,
    };

    // step 5
    if (orderId === "0") {
      return;
    } else {
      OrderService.updateOrder(order, orderId).then((res) => {
        this.componentDidMount();
      });
    }
  }

  invoiceVendor = (row) => {
    this.updateOrder(row.orderId);
  };
  invoiceVendorMultipleOrdersRider = () => {
    this.state.selctedOrderRows.map((row) => this.invoiceVendor(row));
  };
  calculateInvoiceAmount = async () => {
    let amountPayout = 0,
      index = 0,
      invoiceableOrders = [];
    this.state.selctedOrderRows.map((row) => {
      if (
        row.orderStatus === "DELIVERED" &&
        row.vendorPaymentStatus === "UNPAID"
      ) {
        amountPayout = amountPayout + row.vendorCost;
        invoiceableOrders[index++] = row.orderId;
      }
    });

    let result = await confirm(
      "Total Invoiceable Orders: [" +
        invoiceableOrders.length +
        "] Order IDs: [" +
        invoiceableOrders +
        "] Invoiceable Amount: [" +
        amountPayout.toFixed(2) +
        "] Are you sure, You want to proceed with Invoice?"
    );
    if (result) {
      invoiceableOrders.map((orderId) => {
        //alert("order" + orderId);
        this.updateOrder(orderId);
      });
    } else {
      alert("Invoice Cancelled!");
      return;
    }
  };
  handleChange = (state) => {
    this.state.selctedOrderRows = state.selectedRows;
  };
  viewOrder(id) {
    this.props.history.push(`/view-order/${id}`);
  }
  editOrder(id) {
    this.props.history.push(`/add-order/${id}`);
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      this.setState({ redirect: "/login" });
    }
    this.setState({ currentUser: currentUser, userReady: true });
    console.log("currentUser.roles :: " + currentUser?.roles);
    let orderFilter = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      orderStatus: this.state.orderStatus,
    };
    if (currentUser?.roles?.includes("ROLE_STORE")) {
      OrderService.getStoreOrdersByStatus(
        currentUser?.storeId,
        orderFilter
      ).then((res) => {
        //console.log(res.data);
        this.setState({ orders: res.data });
        this.setState({
          tableData: { columns: this.state.columns, data: res.data },
        });
      });
    } else {
      OrderService.getOrdersByStatus(orderFilter).then((res) => {
        //console.log(res.data);
        this.setState({ orders: res.data });
        this.setState({
          tableData: { columns: this.state.columns, data: res.data },
        });
      });
    }
  }

  addOrder() {
    this.props.history.push("/add-order/0");
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      // <!-- Page Wrapper -->
      <div id="wrapper">
        {this.state.currentUser?.roles.includes("ROLE_ADMIN") && (
          <SideBarComponent />
        )}
        {this.state.currentUser?.roles.includes("ROLE_STORE") && (
          <SideBarStoreComponent />
        )}
        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" class="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            <TopBarComponent />

            {/* <!-- Begin Page Content --> */}
            <div class="container-fluid">
              {/* <!-- Page Heading --> */}
              {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Order</h1>
                
                </div> */}

              {/* <!-- Content Row --> */}
              {/* <!-- Content Row --> */}
              <div class="row">
                <div class="col-xl-12 col-lg-12">
                  {/* <!-- Area Chart --> */}
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <h6 class="m-0 font-weight-bold text-primary d-flex justify-content-between align-items-center">
                        List Order
                        {/* {this.state.currentUser?.roles?.includes("ROLE_ADMIN") && (
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={this.calculateInvoiceAmount}
                          >
                            Mark Invoiced
                          </button>
                        )} */}
                        {this.state.currentUser?.roles?.includes(
                          "ROLE_STORE"
                        ) && (
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={this.addOrder}
                          >
                            Add Order
                          </button>
                        )}
                      </h6>
                    </div>
                    <div class="card-body">
                      <form onSubmit={this.onFormSubmit}>
                        <div className="row">
                          <div className="col-md-3">
                            Start Date:
                            <DatePicker
                              selected={this.state.startDate}
                              onChange={this.handleStartDateChange}
                              name="startDate"
                              dateFormat="dd-MM-yyyy"
                            />
                          </div>

                          <div className="col-md-3">
                            End Date:{" "}
                            <DatePicker
                              selected={this.state.endDate}
                              onChange={this.handleEndDateChange}
                              name="endDate"
                              dateFormat="dd-MM-yyyy"
                            />
                          </div>

                          <div className="col-md-3">
                            Order Status:
                            <select
                              name="orderStatus"
                              value={this.state.orderStatus}
                              onChange={this.orderStatusHandler}
                            >
                              <option value="">Select Order Status</option>
                              {this.state.orderStatuses.map((orderStatus) => (
                                <option value={orderStatus.label}>
                                  {orderStatus.label}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="col-md-3">
                            <button className="btn btn-primary">Search</button>
                          </div>
                        </div>
                      </form>

                      <div className="row">
                        <DataTableExtensions {...this.state.tableData}>
                          <DataTable
                            columns={this.state.columns}
                            data={this.state.orders}
                            defaultSortField="Id"
                            defaultSortDesc={true}
                            pagination
                            highlightOnHover
                            selectableRows
                            print={false}
                            onSelectedRowsChange={this.handleChange}
                            selectedRows={this.handleChange}
                          />
                        </DataTableExtensions>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Content Row --> */}

              {/* <!-- Content Row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
          <BackEndFooterComponent />
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      // <!-- End of Page Wrapper -->
    );
  }
}

export default ListOrderComponent;
