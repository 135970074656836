import React, { Component } from "react";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";

import AuthService from "../services/auth.service";
import HeaderComponent from "./HeaderComponent";
import FooterComponent from "./FooterComponent";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

class NoAccessComponent extends Component {
  constructor(props) {
    super(props);

    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      email: "",
      password: "",
      loading: false,
      message: "",
    };
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true,
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.login(this.state.email, this.state.password).then(
        () => {
          const currentUser = AuthService.getCurrentUser();
          if (currentUser?.roles?.includes("ROLE_STORE")) {
            this.props.history.push("/dashboardStore");
          } else {
            this.props.history.push("/dashboard");
          }

          window.location.reload();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage,
          });
        }
      );
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  // deleteEmployee(id){
  //     EmployeeService.deleteEmployee(id).then( res => {
  //         this.setState({employees: this.state.employees.filter(employee => employee.id !== id)});
  //     });
  // }
  // viewEmployee(id){
  //     this.props.history.push(`/view-employee/${id}`);
  // }
  // editEmployee(id){
  //     this.props.history.push(`/add-employee/${id}`);
  // }

  // componentDidMount(){
  //     EmployeeService.getEmployees().then((res) => {
  //         this.setState({ employees: res.data});
  //     });
  // }

  // addEmployee(){
  //     this.props.history.push('/add-employee/0');
  // }

  // viewCustomer(){
  //     this.props.history.push('/customers');
  // }

  render() {
    return (
      <div>
        <HeaderComponent />
        <h2 className="text-center">
          No Access! Please check with Admin or Login Again
        </h2>

        <div class="container">
          {/* <!-- Outer Row --> */}
          <div class="row justify-content-center">
            <div class="col-xl-10 col-lg-12 col-md-9">
              <div class="card o-hidden border-0 shadow-lg my-5">
                <div class="card-body p-0">
                  {/* <!-- Nested Row within Card Body --> */}
                  <div class="row">
                    <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
                    <div class="col-lg-6">
                      <div class="p-5">
                        <div class="text-center">
                          <h1 class="h4 text-gray-900 mb-4">Welcome Back!</h1>
                        </div>

                        <Form
                          onSubmit={this.handleLogin}
                          ref={(c) => {
                            this.form = c;
                          }}
                        >
                          {/* <form class="user"> */}

                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control form-control-user"
                              id="exampleInputEmail"
                              aria-describedby="emailHelp"
                              value={this.state.email}
                              onChange={this.onChangeEmail}
                              required
                              placeholder="Enter Email Address..."
                              name="email"
                            />
                          </div>

                          <div class="form-group">
                            <input
                              type="password"
                              class="form-control form-control-user"
                              id="exampleInputPassword"
                              placeholder="Password"
                              required
                              name="password"
                              value={this.state.password}
                              onChange={this.onChangePassword}
                            />
                          </div>
                          <div class="form-group">
                            <div class="custom-control custom-checkbox small">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="customCheck"
                              />
                              <label
                                class="custom-control-label"
                                for="customCheck"
                              >
                                Remember Me
                              </label>
                            </div>
                          </div>
                          {/* <a href="index.html" class="btn btn-primary btn-user btn-block">
                                            Login
                                        </a> */}

                          {/* </form> */}

                          <div className="form-group">
                            <button
                              className="btn btn-primary btn-block"
                              disabled={this.state.loading}
                            >
                              {this.state.loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                              )}
                              <span>Login</span>
                            </button>
                          </div>

                          {this.state.message && (
                            <div className="form-group">
                              <div className="alert alert-danger" role="alert">
                                {this.state.message}
                              </div>
                            </div>
                          )}
                          <CheckButton
                            style={{ display: "none" }}
                            ref={(c) => {
                              this.checkBtn = c;
                            }}
                          />
                        </Form>
                        <hr />
                        <div class="text-center">
                          <a class="small" href="/forgot">
                            Forgot Password?
                          </a>
                        </div>
                        <div class="text-center">
                          <a class="small" href="/register">
                            Create an Account!
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <FooterComponent />
      </div>
    );
  }
}

export default NoAccessComponent;
