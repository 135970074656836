import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import AuthService from "../services/auth.service";
import VendorService from "../services/VendorService";
import SideBarComponent from "./SideBarComponent";
import BackEndFooterComponent from "./BackEndFooterComponent";
import TopBarComponent from "./TopBarComponent";

class CreateVendorComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // step 2
      id: this.props.match.params.id,
      vendorName: "",
      vendorNameVal: "",
      vendorMobileNumber: "",
      vendorMobileNumberVal: "",

      vendorAddress: "",
      vendorAddressVal: "",

      vendorLatitude: "",
      vendorLatitudeVal: "",

      vendorLongitude: "",
      vendorLongitudeVal: "",

      vendorLongitude: "",
      vendorLongitudeVal: "",

      currentUser: null,
      postalCode: "",
      postalCodeVal: "",

      fixedVendorKMs: "",
      fixedVendorKMsVal: "",

      fixedVendorRate: "",
      fixedVendorRateVal: "",

      perKMVendorRate: "",
      perKMVendorRateVal: "",
    };
    this.changeVendorNameHandler = this.changeVendorNameHandler.bind(this);
    this.saveOrUpdateVendor = this.saveOrUpdateVendor.bind(this);
  }

  // step 3
  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      this.setState({ redirect: "/login" });
    }
    this.setState({ currentUser: currentUser, userReady: true });
    console.log("currentUser" + JSON.stringify(currentUser?.roles));

    if (!currentUser?.roles.includes("ROLE_ADMIN")) {
      console.log("currentUser" + JSON.stringify(currentUser.roles));
      this.setState({ redirect: "/noaccess" });
    }

    // step 4
    if (this.state.id === "0") {
      return;
    } else {
      VendorService.getVendorById(this.state.id).then((res) => {
        let vendor = res.data;

        this.setState({
          vendorName: vendor.vendorName,
          vendorAddress: vendor.vendorAddress,
          vendorMobileNumber: vendor.vendorMobileNumber,
          postalCode: vendor.postalCode,
          vendorLatitude: vendor.vendorLatitude,
          vendorLongitude: vendor.vendorLongitude,
          fixedVendorKMs: vendor.fixedVendorKMs,
          fixedVendorRate: vendor.fixedVendorRate,
          perKMVendorRate: vendor.perKMVendorRate,
        });
      });
    }
  }
  saveOrUpdateVendor = (e) => {
    e.preventDefault();

    if (this.state.vendorName === "") {
      this.setState({ vendorNameVal: "Invalid!, Please enter  Vendor Name" });
      return false;
    }
    else  {
      this.setState({ vendorNameVal: "" });
    }
    if (this.state.vendorMobileNumber === "") {
      this.setState({
        vendorMoblieNumberVal:
          "Invalid!, Please enter vendor Contact Number ",
      });
      return false;
    }
    else  {
      this.setState({vendorMoblieNumberVal:"" });
    }
    if (this.state.vendorAddress === "") {
      this.setState({
        vendorAddressVal: "Invalid!, Please enter Vendor Address",
      });
      return false;
    }
    else  {
      this.setState({ vendorAddressVal: "" });
    }
    if (this.state.postalCode === "") {
      this.setState({
        postalCodeVal: "Invalid!, Please enter  Postal Code",
      });
      return false;

    }
    else  {
      this.setState({ postalCodeVal: "" });
    }


    if (this.state.vendorLatitude === "") {
      this.setState({
        vendorLatitudeVal: "Invalid!, Please enter Vendor Latitude ",
      });
      return false;
    }

    else  {
      this.setState({ vendorLatitudeVal: "" });
    }
    if (this.state.vendorLongitude === "") {
      this.setState({
        vendorLongitudeVal: "Invalid!, Please enter Vendor Longitude ",
      });
      return false;
    }
    else  {
      this.setState({ vendorLongitudeVal: "" });
    }
    if (this.state.perKMVendorRate === "") {
      this.setState({
            perKMVendorRateVal: "Invalid!, Please enter per KM Vendor Rate",
      });
      return false;
    }
    else  {
      this.setState({ perKMVendorRateVal: "" });
    }
    
    if (this.state.fixedVendorRate === "") {
      this.setState({
        fixedVendorRateVal: "Invalid!, Please enter fixed Vendor Rate",
      });
      return false;
    }
    else  {
      this.setState({ fixedVendorRateVal: "" });
    }
    if (this.state.fixedVendorKMs === "") {
      this.setState({
        fixedVendorKMsVal: "Invalid!, Please select fixed Vendor KMs",
      });
      return false;
    }
    else  {
      this.setState({ fixedVendorKMsVal: "" });
    }

    
    let vendor = {
      vendorName: this.state.vendorName,
      vendorAddress: this.state.vendorAddress,
      postalCode: this.state.postalCode,
      vendorLatitude: this.state.vendorLatitude,
      vendorLongitude: this.state.vendorLongitude,
      vendorMobileNumber: this.state.vendorMobileNumber,
      fixedVendorKMs: this.state.fixedVendorKMs,
      fixedVendorRate: this.state.fixedVendorRate,
      perKMVendorRate: this.state.perKMVendorRate,
    };
    console.log("vendor => " + JSON.stringify(vendor));

    // step 5
    if (this.state.id === "0") {
      VendorService.createVendor(vendor).then((res) => {
        this.props.history.push("/vendors");
      });
    } else {
      VendorService.updateVendor(vendor, this.state.id).then((res) => {
        this.props.history.push("/vendors");
      });
    }
  };

  changeVendorNameHandler = (event) => {
    this.setState({ vendorName: event.target.value });
  };
  changeBrandIdHandler = (event) => {
    this.setState({ brandId: event.target.value });
  };

  changeCityIdHandler = (event) => {
    this.setState({ cityId: event.target.value });
  };

  changeVendorAddressHandler = (event) => {
    this.setState({ vendorAddress: event.target.value });
  };
  changePostalCodeHandler = (event) => {
    this.setState({ postalCode: event.target.value });
  };
  changevendorNumberHandler = (event) => {
    this.setState({ vendorMobileNumber: event.target.value });
  };

  changeVendorLatitudeHandler = (event) => {
    this.setState({ vendorLatitude: event.target.value });
  };

  changeVendorLongitudeHandler = (event) => {
    this.setState({ vendorLongitude: event.target.value });
  };
  changePerKMVendorRateHandler = (event) => {
    this.setState({ perKMVendorRate: event.target.value });
  };

  changeFixedVendorRateHandler = (event) => {
    this.setState({ fixedVendorRate: event.target.value });
  };

  changeFixedVendorKMsHandler = (event) => {
    this.setState({ fixedVendorKMs: event.target.value });
  };

  cancel() {
    this.props.history.push("/Vendors");
  }

  getTitle() {
    if (this.state.id === "0") {
      return <h6 className="m-0 font-weight-bold text-primary">Add Vendor</h6>;
    } else {
      return (
        <h6 className="m-0 font-weight-bold text-primary">Update Vendor</h6>
      );
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    //     const { currentUser } = this.state;

    return (
      // <!-- Page Wrapper -->
      <div id="wrapper">
        <SideBarComponent />

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" class="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            <TopBarComponent />

            {/* <!-- Begin Page Content --> */}
            <div class="container-fluid">
              {/* <!-- Page Heading --> */}
              {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Vendor</h1>
                
                </div> */}

              {/* <!-- Content Row --> */}
              {/* <!-- Content Row --> */}
              <div class="row">
                <div class="col-xl-12 col-lg-12">
                  {/* <!-- Area Chart --> */}
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <a
                        class="btn btn-sm btn-primary float-right   mr-3"
                        href="/Vendors"
                        role="button"
                      >
                        List Vendor
                      </a>

                      {this.getTitle()}
                    </div>
                    <div class="card-body">
                      <form>
                        <div className="row">
                          <div className="card col-md-6">
                            <div className="card-body">
                              <div className="form-group">
                                <label> Vendor Name: </label>
                                <input
                                  placeholder="Vendor Name"
                                  name="vendorName"
                                  className="form-control"
                                  value={this.state.vendorName}
                                  onChange={this.changeVendorNameHandler}
                                /> <p className="text-danger">
                                {" "}
                                {this.state.vendorNameVal}
                              </p>
                          
                              </div>

                              <div className="form-group">
                                <label> Vendor Contact: </label>
                                <input
                                  placeholder="Vendor Contact"
                                  name="vendorNumber"
                                  maxLength="11"
                                  className="form-control"
                                  value={this.state.vendorMobileNumber}
                                  onChange={this.changevendorNumberHandler}
                                />
                                 <p className="text-danger">
                                  {" "}
                                  {this.state.vendorMoblieNumberVal}
                                </p>
                              
                              </div>
                              <div className="form-group">
                                <label> Vendor Address: </label>
                                <input
                                  placeholder="Vendor Address"
                                  name="vendorAddress"
                                  className="form-control"
                                  value={this.state.vendorAddress}
                                  onChange={this.changeVendorAddressHandler}
                                />
                                 <p className="text-danger">
                                  {" "}
                                  {this.state.vendorAddressVal}
                                </p>
                              </div>
                              <div className="form-group">
                                <label> Vendor Postal Code: </label>
                                <input
                                  placeholder="Vendor Postal Code"
                                  name="postalCode"
                                  className="form-control"
                                  maxLength="8"
                                  value={this.state.postalCode}
                                  onChange={this.changePostalCodeHandler}
                                />
                                 <p className="text-danger">
                                  {" "}
                                  {this.state.postalCodeVal}
                                </p>

                              </div>
                              <div className="form-group">
                                <label> Vendor Location Latitude: </label>
                                <input
                                  placeholder="Vendor Location Latitude: "
                                  name="vendorLatitude"
                                  className="form-control"
                                  value={this.state.vendorLatitude}
                                  onChange={this.changeVendorLatitudeHandler}
                                />
                                 <p className="text-danger">
                                  {" "}
                                  {this.state.vendorLatitudeVal}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="card col-md-6">
                            <div className="card-body">
                              <div className="form-group">
                                <label> Vendor Location Longitude: </label>
                                <input
                                  placeholder="Vendor Location Longitude"
                                  name="vendorLongitude"
                                  className="form-control"
                                  value={this.state.vendorLongitude}
                                  onChange={this.changeVendorLongitudeHandler}
                                />
                                 <p className="text-danger">
                                  {" "}
                                  {this.state.vendorLongitudeVal}
                                </p>
                              </div>
                              
                              <div className="form-group">
                                <label> Per KM Vendor Rate: </label>
                                <input
                                  placeholder="Per KM Vendor Rate"
                                  name="perKMVendorRate"
                                  className="form-control"
                                  value={this.state.perKMVendorRate}
                                  onChange={this.changePerKMVendorRateHandler}
                                />
                                 <p className="text-danger">
                                  {" "}
                                  {this.state.perKMVendorRateVal}
                                </p>
                              </div>
                              <div className="form-group">
                                <label>Fixed Vendor Rate: </label>
                                <input
                                  placeholder="Fixed Vendor Rate"
                                  name="fixedVendorRate"
                                  className="form-control"
                                  value={this.state.fixedVendorRate}
                                  onChange={this.changeFixedVendorRateHandler}
                                />
                                 <p className="text-danger">
                                  {" "}
                                  {this.state.fixedVendorRateVal}
                                </p>
                              
                                
                              </div>
                              <div className="form-group">
                                <label>Fixed Vendor KMs: </label>
                                <input
                                  placeholder="Fixed Vendor KMs"
                                  name="fixedVendorKMs"
                                  className="form-control"
                                  value={this.state.fixedVendorKMs}
                                  onChange={this.changeFixedVendorKMsHandler}
                                />
                                 <p className="text-danger">
                                  {" "}
                                  {this.state.fixedVendorKMsVal}
                                </p>
                              
                              </div>
                              <br></br>
                              <button
                                className="btn btn-success"
                                onClick={this.saveOrUpdateVendor}
                              >
                                Save
                              </button>
                              <button
                                className="btn btn-danger"
                                onClick={this.cancel.bind(this)}
                                style={{ marginLeft: "10px" }}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>

                      <hr />
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Content Row --> */}

              {/* <!-- Content Row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
          <BackEndFooterComponent />
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      // <!-- End of Page Wrapper -->
    );
  }
}

export default CreateVendorComponent;
