import axios from "axios";

const DEALER_API_BASE_URL = "https://api.richgoldshine.com/quick/api/v1/users";

class UserService {
  getUsers() {
    return axios.get(DEALER_API_BASE_URL);
  }

  createUser(user) {
    return axios.post(DEALER_API_BASE_URL, user);
  }

  getUserById(userId) {
    return axios.get(DEALER_API_BASE_URL + "/" + userId);
  }

  updateUser(user, userId) {
    return axios.put(DEALER_API_BASE_URL + "/" + userId, user);
  }

  deleteUser(userId) {
    return axios.delete(DEALER_API_BASE_URL + "/" + userId);
  }
  forgotPassword(user) {
    return axios.put(DEALER_API_BASE_URL + "/forgot", user);
  }
}

export default new UserService();
