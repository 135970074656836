import React, { Component } from "react";
import StoreService from "../services/StoreService";
import SideBarComponent from "./SideBarComponent";
import BackEndFooterComponent from "./BackEndFooterComponent";
import TopBarComponent from "./TopBarComponent";
import AuthService from "../services/auth.service";
import { Redirect } from "react-router-dom";

class ViewStoreComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      store: {},
      currentUser: null,
    };

    this.viewStores = this.viewStores.bind(this);
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      this.setState({ redirect: "/login" });
    }
    this.setState({ currentUser: currentUser, userReady: true });
    console.log("currentUser" + JSON.stringify(currentUser?.roles));

    if (!currentUser?.roles.includes("ROLE_ADMIN")) {
      console.log("currentUser" + JSON.stringify(currentUser.roles));
      this.setState({ redirect: "/noaccess" });
    }
    StoreService.getStoreById(this.state.id).then((res) => {
      this.setState({ store: res.data });
    });
  }

  viewStores() {
    this.props.history.push("/stores");
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      // <!-- Page Wrapper -->
      <div id="wrapper">
        <SideBarComponent />

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" class="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            <TopBarComponent />

            {/* <!-- Begin Page Content --> */}
            <div class="container-fluid">
              {/* <!-- Page Heading --> */}
              {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Store</h1>
                
                </div> */}

              {/* <!-- Content Row --> */}
              {/* <!-- Content Row --> */}
              <div class="row">
                <div class="col-xl-12 col-lg-12">
                  {/* <!-- Area Chart --> */}
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <h6 class="m-0 font-weight-bold text-primary d-flex justify-content-between align-items-center">
                        View Store
                        <button
                          className="btn btn-primary"
                          onClick={this.viewStores}
                        >
                          {" "}
                          List Store
                        </button>
                      </h6>
                    </div>
                    <div class="card-body">
                      {/* <h2 className="text-center">Stores List</h2> */}
                      <div className="row"></div>
                      <br></br>
                      <div className="card col-md-6 offset-md-3">
                        <h3 className="text-center"> View Store Details</h3>
                        <div className="card-body">
                          <div className="row">
                            <label style={{ fontWeight: "bold" }}>
                              {" "}
                              Store Name :{" "}
                            </label>{" "}
                            <div> {this.state.store.storeName}</div>
                          </div>

                          <div className="row">
                            <label style={{ fontWeight: "bold" }}>
                              {" "}
                              Store Contact:{" "}
                            </label>
                            <div> {this.state.store.storeMobileNumber}</div>
                          </div>
                          <div className="row">
                            <label style={{ fontWeight: "bold" }}>
                              {" "}
                              Store Address:{" "}
                            </label>
                            <div> {this.state.store.storeAddress}</div>
                          </div>
                          <div className="row">
                            <label style={{ fontWeight: "bold" }}>
                              {" "}
                              Store Postal Code:{" "}
                            </label>
                            <div> {this.state.store.postalCode}</div>
                          </div>
                          <div className="row">
                            <label style={{ fontWeight: "bold" }}>
                              {" "}
                              Store Latitude:{" "}
                            </label>
                            <div> {this.state.store.storeLatitude}</div>
                          </div>
                          <div className="row">
                            <label style={{ fontWeight: "bold" }}>
                              {" "}
                              Store Longitude:{" "}
                            </label>
                            <div> {this.state.store.storeLongitude}</div>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Content Row --> */}

              {/* <!-- Content Row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
          <BackEndFooterComponent />
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      // <!-- End of Page Wrapper -->
    );
  }
}

export default ViewStoreComponent;
