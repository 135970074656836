import axios from "axios";

const DEALER_API_BASE_URL = "https://api.richgoldshine.com/quick/api/v1/stores";

class StoreService {
  getStores() {
    return axios.get(DEALER_API_BASE_URL);
  }

  createStore(store) {
    return axios.post(DEALER_API_BASE_URL, store);
  }

  getStoreById(storeId) {
    return axios.get(DEALER_API_BASE_URL + "/" + storeId);
  }

  updateStore(store, storeId) {
    return axios.put(DEALER_API_BASE_URL + "/" + storeId, store);
  }

  deleteStore(storeId) {
    return axios.delete(DEALER_API_BASE_URL + "/" + storeId);
  }

  createStoreInquiry(dealerInquiry) {
    return axios.post(DEALER_API_BASE_URL + "/dealerInquiry", dealerInquiry);
  }

  getStoresInquiry() {
    return axios.get(DEALER_API_BASE_URL + "/dealerInquiry");
  }

  getStoreByBrandId(brandId) {
    return axios.get(DEALER_API_BASE_URL + "/dealerInquiry" + brandId);
  }

  createAvailability(carAvailability) {
    return axios.post(
      DEALER_API_BASE_URL + "/dealerCarAvailability",
      carAvailability
    );
  }

  getCarAvailability() {
    return axios.get(DEALER_API_BASE_URL + "/carAvailability");
  }
}

export default new StoreService();
